const OrgInfoLead = ({ row, extraData }) => {
    const fiscalYear = row['devTags.FiscalYear'];
    const match = extraData['extraData.orgStaffProgress']?.find(item => item.devTags?.FiscalYear === fiscalYear);
    if (match) {
        return match.variables.org_PCANCname2;
    }

    return null;
};

export default OrgInfoLead;
