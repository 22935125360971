import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default async (props, config, values) => {
    const { deploymentInfo, groupingUnitId, orgList } = props;
    const { flowProps, gql, pawnType } = config;

    const gqlVariableBank = {
        groupingUnitId,
        deploymentHash: deploymentInfo.hash,
        orgList: props.isEdit ? values?.multiSelect?.map(item => item.value) : orgList?.map(item => item.value),
        orgMember: props.orgMember?.value,
        deploymentId: props.deploymentId,
        assignmentId: deploymentInfo.assignmentId,
        // pawnRegex: new RegExp(`"mc_5636_id": "4_${props.orgMember?.value}"`).toString(),
        metricsPawn: props.metricsPawn?.pawnId,
        actingPawn: Fortress.user.acting.id,
    };
    // infoResources is different
    if (deploymentInfo.handle === 'infoResources') {
        return {
            pawnId: Fortress.user.acting.id,
            pawnHash: Fortress.user.acting.random_hash,
            deploymentId: props.deploymentId,
        };
    }
    const gqlVars = {};

    for (const [key, value] of Object.entries(gql.variables)) {
        const match = gqlVariableBank[value];

        if (match) {
            gqlVars[key] = match;
        }
    }

    const response = await client.query({
        query: gql.query,
        fetchPolicy: 'network-only',
        variables: gqlVars,
    });

    const roles = response?.data?.roles;
    const groupingUnit = response?.data?.groups?.groupingUnit;

    const userLists = {
        staffMemberList: roles?.uberadminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        groupedAdminFacilitatorList: groupingUnit?.childRoles?.guAdminList
            ?.map(item => {
                return {
                    pawnId: item.pawn.pawnId,
                    pawnHash: item.pawn.pawnHash,
                    name: item.user.fullName,
                };
            })
            ?.concat(
                groupingUnit?.childRoles?.instructorList?.map(item => {
                    return {
                        pawnId: item.pawn.pawnId,
                        pawnHash: item.pawn.pawnHash,
                        name: item.user.fullName,
                    };
                })
            ),
        groupedOrgAdminList: groupingUnit?.childRoles?.guAdminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        otherOrgAdminList: roles?.otherAdmins?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        orgList: orgList?.map(item => {
            return {
                pawnId: item.pawnId,
                pawnHash: item.pawnHash,
                name: item.label,
            };
        }),
        adminFacilitatorList: roles?.guAdminList
            ?.map(item => {
                return {
                    pawnId: item.pawn.pawnId,
                    pawnHash: item.pawn.pawnHash,
                    name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                };
            })
            ?.concat(
                roles?.instructorList?.map(item => {
                    return {
                        pawnId: item.pawn.pawnId,
                        pawnHash: item.pawn.pawnHash,
                        name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                    };
                })
            ),
        orgAdminList: roles?.guAdminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
            };
        }),
    };

    const flowVars = {
        orgSelection: values && JSON.stringify(values),
        lastUpdatedBy: `${Fortress.user.acting.user.full_name}, ${Fortress.user.acting.user.username}`,
        organizationName: groupingUnit?.group?.label,
        organizationId: groupingUnit?.group?.groupId,
        staffMembers: roles?.uberadminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        groupedOrgAdmins: groupingUnit?.childRoles?.guAdminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        otherOrgAdmins: roles?.guAdminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        adminFacilitators: roles?.guAdminList
            ?.map(item => {
                return {
                    id: item.pawn.pawnId,
                    name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                };
            })
            ?.concat(
                roles?.instructorList?.map(item => {
                    return {
                        id: item.pawn.pawnId,
                        name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                    };
                })
            ),
        orgAdmins: roles?.guAdminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
            };
        }),
        goals: response?.data?.flows?.assignmentProgressList
            ?.filter(item => item.devTags.DocumentationText)
            .map(item => {
                const arr = item?.devTags?.GoalPerson?.split('-');

                return {
                    id: item.assignmentProgressId,
                    name: `${item.devTags.DocumentationText} - ${arr[0]}`,
                };
            }),
        orgMemberName: props.orgMember?.label,
        orgMemberId: props.orgMember?.value,
        orgDSS: groupingUnit?.fields?.regionDSS,
        orgDPH: groupingUnit?.fields?.regionDPH,
        orgAddress: groupingUnit?.fields?.groupAddress,
        orgZip: groupingUnit?.fields?.groupZip,
        orgCity: groupingUnit?.fields?.groupCity,
        orgState: groupingUnit?.fields?.state,
    };

    const pawns = {
        metricspawn: {
            pawnId: groupingUnit?.childRoles?.metricspawn?.pawn?.pawnId,
            pawnHash: groupingUnit?.childRoles?.metricspawn?.pawn?.pawnHash,
            respondentHash: groupingUnit?.childRoles?.metricspawn?.pawn?.respondentHash,
        },
        actingPawn: {
            pawnId: Fortress.user.acting.id,
            pawnHash: Fortress.user.acting.random_hash,
        },
        orgMember: {
            pawnId: props.orgMember?.value,
            pawnHash: props.orgMember?.hash,
        },
    };

    const deploymentProps = {
        flowProps: {
            hidePlayButton: true,
            userLists: {},
            variables: {},
        },
    };
    const sortAlphabetically = list => {
        const arr = [...list];

        arr.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return arr;
    };
    if (flowProps?.variables?.length > 0) {
        flowProps.variables.forEach(variable => {
            const match = flowVars[variable];
            if (match) {
                deploymentProps.flowProps.variables[variable] = Array.isArray(match)
                    ? sortAlphabetically(match)
                    : match;
            }
        });
    }
    // assemble userListså
    if (flowProps?.userLists?.length > 0) {
        flowProps.userLists.forEach(list => {
            const match = userLists[list];

            if (match) {
                deploymentProps.flowProps.userLists[list] = Array.isArray(match) ? sortAlphabetically(match) : match;
            }
        });
    }

    const useMetricsPawn =
        deploymentInfo.handle === 'technicalAssistance' ||
        deploymentInfo.handle === 'goalsTA' ||
        deploymentInfo.handle === 'planningProgress';
    const surveyShouldRepeat =
        deploymentInfo.handle === 'profDevDoc' || useMetricsPawn || deploymentInfo.handle === 'networkMeetings';

    if (props.isNew && surveyShouldRepeat && !props.isEdit && !props.isView && !props.isFormEdit) {
        try {
            let res = await axios.get(
                Boilerplate.route('api.assignmentDeployment.repeated', {
                    deploymentId: props.deploymentId,
                    pawnId: useMetricsPawn ? pawns.metricspawn.pawnId : Fortress.user.acting.id,
                    hash: useMetricsPawn ? pawns.metricspawn.respondentHash : Fortress.user.acting.respondent_hash,
                }),
                {
                    params: {
                        forceRepeat: true,
                    },
                }
            );

            if (res?.data?.data?.deploymentId) {
                deploymentProps.deploymentId = res.data.data.deploymentId;
            }
        } catch (e) {
            console.error(e);
        }
    } else {
        deploymentProps.deploymentId =
            props.isEdit || props.isView || props.isFormEdit
                ? props.deploymentId
                : response?.data?.flows?.deployment?.deploymentId;
    }

    deploymentProps.pawnId = pawns[pawnType].pawnId;

    deploymentProps.pawnHash = pawns[pawnType].pawnHash;

    return deploymentProps;
};
