import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, CccisdSelect } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import groupsQuery from './graphql/filters/groups.graphql';
import deploymentsQuery from './graphql/filters/deployments.graphql';
import orgMembersQuery from './graphql/filters/orgMembers.graphql';
import DashboardTable from './Table/index.js';
import Survey from './Table/components/Survey/index.js';
import Modal from 'cccisd-modal';
import style from './style.css';
import dashboardConfig from './config.js';
import Tooltip from 'cccisd-tooltip';

const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;
const actingId = Fortress.user.acting.id;
const actingGroupId = Fortress.user.acting.group?.id;
const isNotUber = actingRole === 'guAdmin' || actingRole === 'instructor';

const Dashboard = () => {
    const [projectId, setProjectId] = useState(null);
    const [groupingUnitId, setGroupingUnitId] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [deploymentInfo, setDeploymentInfo] = useState(null);
    const [metricspawn, setMetricsPawn] = useState(null);
    const [orgMember, setOrgMember] = useState(null);
    // const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(true);
    const modal = useRef();
    useEffect(() => {
        initializeFilters();
    }, []);

    useEffect(() => {
        setLoading(true);
        getDeploymentOptions(projectId);
    }, [projectId]);

    const sortAlphabetically = list => {
        const arr = [...list];

        arr.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        return arr;
    };

    const initializeFilters = async () => {
        // Determine Role
        // Determine Initial project
        // get deployment Options

        const response = await client.query({
            query: groupsQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        const guList = sortAlphabetically(
            response?.data?.groups?.groupingUnitList?.map(item => {
                return {
                    value: item.group.groupId,
                    label: item.group.label,
                    pawnId: item.childRoles.metricspawn.pawn.pawnId,
                    pawnHash: item.childRoles.metricspawn.pawn.pawnHash,
                };
            })
        );

        const projectList = dashboardConfig[actingRole].questProjectList.map(item => item.label);
        const questProjectList = sortAlphabetically(
            response.data.groups.questprojectList
                .filter(item => projectList.includes(item.group.label))
                .map(item => {
                    return {
                        value: item.group.groupId,
                        label: item.group.label,
                    };
                })
        );
        guList.unshift({
            value: 0,
            label: '-- Please Select a TA Org --',
            pawnId: 0,
            pawnHash: 0,
        });
        setSelectOptions({ guList, questProjectList });
        setProjectId(+questProjectList[0]?.value);

        const guId = isNotUber ? actingGroupId : guList[0]?.value;

        setGroupingUnitId(guId);

        // NOTE!!! NEED metricspawn from orgAdmins group
        setMetricsPawn({ pawnId: guList[0]?.pawnId, pawnHash: guList[0]?.pawnHash });
        await getDeploymentOptions(+questProjectList[0]?.value, guList[0]?.label, guId);
    };

    const getDeploymentOptions = async (groupId, orgName, guId) => {
        const response = await client.query({
            query: deploymentsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: groupId ? groupId : projectId,
            },
        });

        const assignmentList = response?.data?.flows?.assignmentList;

        let deploymentList = [];

        let roleDeployments = [];

        dashboardConfig[actingRole].questProjectList.forEach(proj => {
            proj.deployments.forEach(dep => {
                roleDeployments.push(dep.handle);
            });
        });

        assignmentList.forEach(assign => {
            const deploymentOptions = assign.deploymentList
                .filter(dep => roleDeployments.includes(dep.deploymentHandle))
                .map(dep => {
                    return {
                        value: dep.deploymentId,
                        label: dep.label,
                        deploymentHandle: dep.deploymentHandle,
                        hash: dep.hash,
                        isOpen: dep.isOpen,
                        assignmentId: assign.assignmentId,
                        assignmentLabel: assign.label,
                    };
                });

            deploymentList = deploymentList.concat(deploymentOptions);
        });

        setSelectOptions(prevOptions => {
            return { ...prevOptions, deploymentList: sortAlphabetically(deploymentList) };
        });

        const first = sortAlphabetically(deploymentList)[0];

        if (first?.deploymentHandle === 'goalsTA') {
            await getOrgMembers(guId);
        }

        setDeploymentId(first?.value);

        setDeploymentInfo({
            hash: first?.hash,
            handle: first?.deploymentHandle,
            isOpen: first?.isOpen,
            assignmentId: first?.assignmentId,
            assignmentLabel: first?.assignmentLabel,
            orgName,
        });

        setLoading(false);
    };

    const getMatch = (id, type) => {
        let match;
        if (type === 'deployment') {
            match = selectOptions.deploymentList.find(item => item.value === id);
        }
        if (type === 'group') {
            match = selectOptions.guList.find(item => item.value === id);
        }
        if (match) {
            return match;
        }
    };

    const getOrgName = () => {
        if (groupingUnitId) {
            const match = selectOptions.guList.find(item => item.value === groupingUnitId);

            return match?.label;
        }
    };

    // const redirectTo = url => {
    //     setRedirect(url);
    // };
    const getOrgMembers = async guId => {
        const response = await client.query({
            query: orgMembersQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: guId,
            },
        });

        const orgAdmins = response?.data?.roles?.guAdminList.map(item => {
            return { value: item.pawn.pawnId, label: item.user.username, hash: item.pawn.pawnHash };
        });
        const facilitators = response?.data?.roles?.instructorList.map(item => {
            return { value: item.pawn.pawnId, label: item.user.username, hash: item.pawn.pawnHash };
        });
        const list = orgAdmins.concat(facilitators);
        setSelectOptions(prevOptions => {
            return { ...prevOptions, orgMemberList: sortAlphabetically(list) };
        });

        setOrgMember(list[0]);
    };

    const renderSurvey = () => {
        return (
            <div className={`alert-info ${style.buttonWrapper}`}>
                <h3>{deploymentInfo.assignmentLabel}</h3>
                <Modal
                    ref={modal}
                    trigger={
                        <button type="button" className="btn btn-primary">
                            Start Survey
                        </button>
                    }
                    title={deploymentInfo.assignmentLabel ? deploymentInfo.assignmentLabel : 'Survey'}
                    size="large"
                >
                    <div className={style.fixContainer}>
                        <Survey
                            selectOptions={selectOptions}
                            deploymentId={deploymentId}
                            questProjectId={projectId}
                            deploymentInfo={deploymentInfo}
                            modalRef={modal}
                            isReferral
                        />
                    </div>
                </Modal>
            </div>
        );
    };
    const noOrgSelect =
        deploymentInfo?.handle === 'profDevDoc' ||
        deploymentInfo?.handle === 'infoResources' ||
        deploymentInfo?.handle === 'networkMeetings';
    const renderFilters = () => {
        return (
            <>
                {selectOptions && projectId && deploymentId && (
                    <Formik
                        initialValues={{
                            project: projectId,
                            organization: groupingUnitId,
                            survey: deploymentId,
                        }}
                        render={({ values, setFieldValue }) => {
                            return (
                                <Form>
                                    <div className={style.filterBar}>
                                        <Tooltip title="What would you like to do?">
                                            <Field
                                                name="project"
                                                component={CccisdSelect}
                                                label="PCANC Project"
                                                options={selectOptions.questProjectList}
                                                onChange={e => {
                                                    setFieldValue('project', +e.target.value);
                                                    setProjectId(+e.target.value);
                                                }}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Which form would you like to complete?">
                                            <Field
                                                name="survey"
                                                component={CccisdSelect}
                                                label="Survey"
                                                options={selectOptions.deploymentList}
                                                onChange={async e => {
                                                    setFieldValue('survey', +e.target.value);
                                                    setDeploymentId(+e.target.value);
                                                    const match = getMatch(+e.target.value, 'deployment');
                                                    setDeploymentInfo({
                                                        ...deploymentInfo,
                                                        hash: match.hash,
                                                        handle: match.deploymentHandle,
                                                        isOpen: match.isOpen,
                                                        assignmentId: match.assignmentId,
                                                        assignmentLabel: match.assignmentLabel,
                                                    });

                                                    if (match.deploymentHandle === 'goalsTA') {
                                                        await getOrgMembers(groupingUnitId);
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                        {!isNotUber && !noOrgSelect && (
                                            <Tooltip title="For which organization?">
                                                <Field
                                                    name="organization"
                                                    component={CccisdSelect}
                                                    label="TA Org"
                                                    options={selectOptions.guList}
                                                    onChange={async e => {
                                                        setFieldValue('organization', +e.target.value);
                                                        setGroupingUnitId(+e.target.value);
                                                        const match = getMatch(+e.target.value, 'group');

                                                        setMetricsPawn({
                                                            pawnId: match.pawnId,
                                                            pawnHash: match.pawnHash,
                                                        });
                                                        setDeploymentInfo({
                                                            ...deploymentInfo,
                                                            orgName: match.label,
                                                        });
                                                        await getOrgMembers(+e.target.value);
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                        {/* {deploymentInfo.handle === 'goalsTA' &&
                                            selectOptions.orgMemberList && (
                                                <Field
                                                    name="orgMember"
                                                    component={CccisdSelect}
                                                    label="Org Member"
                                                    options={selectOptions.orgMemberList}
                                                    onChange={e => {
                                                        setFieldValue('orgMember', +e.target.value);

                                                        const match = selectOptions.orgMemberList.find(
                                                            item => item.value === +e.target.value
                                                        );

                                                        setOrgMember(match);
                                                    }}
                                                />
                                            )} */}
                                    </div>
                                </Form>
                            );
                        }}
                    />
                )}
            </>
        );
    };

    if (loading) {
        return <Loader loading />;
    }
    const isSurveyOnly = deploymentInfo?.handle === 'infoResources';

    return (
        <div>
            {renderFilters()}
            {isSurveyOnly && <>{renderSurvey()}</>}
            {!groupingUnitId && !noOrgSelect && (
                <div className={`alert ${style.alertSecondary}`} role="alert">
                    Please select a TA Org to view Dashboard data
                </div>
            )}
            {projectId &&
            (groupingUnitId || noOrgSelect) &&
            deploymentId &&
            deploymentInfo?.hash &&
            deploymentInfo?.handle !== 'infoResources' ? (
                <DashboardTable
                    questProjectId={projectId}
                    groupingUnitId={groupingUnitId}
                    deploymentId={deploymentId}
                    deploymentInfo={deploymentInfo}
                    getOrgName={getOrgName}
                    actingId={actingId}
                    actingRole={actingRole}
                    metricsPawn={metricspawn}
                    selectOptions={selectOptions}
                    orgMember={orgMember}
                />
            ) : null}
        </div>
    );
};

export default Dashboard;
